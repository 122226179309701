<template>
  <div class="ticket">
    <!-- 第一部分 -->

    <Head :ticket="ticket" :stopList="stopList" />
    <!-- 人员信息 -->
    <van-cell-group ref="peocont">
      <van-cell center>
        <template #title>
          {{trainchange.peo.passengerName}}&nbsp;
          <van-tag plain type="primary">{{trainchange.peo.travelLevel||"级别不详"}}</van-tag>&nbsp;
          <van-tag plain type="success" v-if="checkCanBuy(trainchange.peo,seatType[openSelectSeat].text)">合规</van-tag>
          <van-tag plain type="danger" v-if="!checkCanBuy(trainchange.peo,seatType[openSelectSeat].text)">超标</van-tag>
        </template>
        <template #label>
          <van-row>
            <span>
              <van-icon class="usericonfont" class-prefix="icon" name="idCard" />{{trainchange.peo.certificateCode}}&nbsp;-&nbsp;</span>
            <span>
              <van-icon class="usericonfont" class-prefix="icon" name="dianhua" />{{trainchange.peo.passengerPhone}}<span class="iconfont icon-xiugai edit-tel" @click="openEdit(trainchange.peo)"></span>
            </span>
          </van-row>
        </template>
      </van-cell>
    </van-cell-group>
    <van-divider v-if="personList.length==0" :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">全部人员均有车票</van-divider>
    <!-- 人员信息-弹出层 -->
    <van-dialog v-model="show" title="修改人员信息" show-cancel-button :beforeClose="subimtTel">
      <van-form ref="editTel">
        <van-field v-model="tel" type="digit" label="手机号" name="validator" placeholder="请输入手机号" :rules="[{ validator, message: '请输入正确的手机号' }]" />
      </van-form>
    </van-dialog>
    <!-- 超标填表 -->
    <van-dialog v-model="compliant" title="超标说明" show-cancel-button :beforeClose="subimtCompliance">
      <van-form ref="editCompliant" class="explanCont">
        <van-field readonly v-model="explanation" label="超标缘由" placeholder="请输入超标缘由" @click="showPicker = true" />
        <van-field :rules="[{ required: true, message: '请填写超标缘由' }]" v-model="explanation1" rows="3" autosize label="备注" type="textarea" maxlength="100" placeholder="请输入备注" show-word-limit />
      </van-form>
      <div style="padding:0 1rem;font-size:12px;color:#777;display:flex">
        <div style="width:60px">
          <small>提示：</small>
        </div>
        <div style="padding-bottom:1rem">
          <small>{{explanationText}}</small>
        </div>
      </div>
    </van-dialog>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>

    <div class="gap"></div>

    <!-- 第二部分 -->
    <!-- 选座 -->
    <van-panel title="选择座位">
      <template #header>
        <div class="chooseTicketBk" style="padding:0 1rem">
          <van-row type="flex" justify="space-between">
            <van-col>
              <van-dropdown-menu>
                <van-dropdown-item v-model="openSelectSeat" :options="seatType" />
              </van-dropdown-menu>
            </van-col>
            <van-col :class="'useSeat '+(seatType[openSelectSeat].SeatNum>5?'blue':'')">余票:{{seatType[openSelectSeat].SeatNum}}</van-col>
          </van-row>
        </div>
      </template>
    </van-panel>
    <!-- 选座提示 -->
    <van-notice-bar text="改签行程不可选座哦" left-icon="info-o" />

    <div class="gap"></div>

    <!-- 第三部分 -->
    <BodyList :travelInfo="travelInfo" :totalPrice="totalPrice" />
    <div class="bottom-gap"></div>

    <!-- 第四部分 -->
    <van-submit-bar :price="totalPrice*100" button-text="提交占座" @submit="onSubmit"></van-submit-bar>

    <!-- 遮罩层 -->
    <van-overlay :show="wrapper" @click="show = false">
      <div class="wrapper" @click.stop>
        <LoadingAnimate :text="'占座中'" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getTrainStopStation, applyChangeOrder } from "@/api/train";
import LoadingAnimate from "@/components/LoadingAnimate";
import LoadingCircle from "@/components/LoadingCircle";
import Head from "./components/InfoHead";
import BodyList from "@/components/InfoList";
import { checkSeat } from "@/services/user";
import { refreshChangeTicket } from "@/services/train";
import { REASONS } from "@/common/config.js";

export default {
  computed: {
    ...mapGetters(["ticket", "travelInfo", "personList", "trainchange", "originalprice"]),
    // 座位类型
    seatType: function () {
      let arr = [];
      let i = 0;
      let which = true;
      this.ticket.displaySeat.forEach((element, index) => {
        if (element.seatType != "停售")
          arr.push({
            text: element.seatType + " (￥" + element.seatPrice + ")",
            value: i++,
            SeatType: element.seatType,
            SeatNum: element.seatNum,
            SeatPrice: element.seatPrice
          });
        if (which && parseInt(element.seatNum) != 0) {
          this.openSelectSeat = index;
          which = false;
        }
      });
      return arr;
    },
    // 总票价
    totalPrice: function () {
      return this.seatType[this.openSelectSeat].SeatPrice - this.originalprice;
    }
  },
  activated() {
    window.setTitle(this.ticket.trainCode);
    // 获取经停站信息
    getTrainStopStation({
      TrainCode: this.ticket.trainCode,
      TrainDate: this.ticket.trainBeginDate,
      FromStation: this.ticket.fromStationName,
      ToStation: this.ticket.toStationName,
      Corp: this.ticket.corp
    }).then(response => {
      let arr = [];
      response.forEach(element => {
        arr.push(...element.data);
      });
      this.stopList = arr;
    });
  },
  data() {
    return {
      showPicker: false,
      columns: REASONS,
      stopList: [], // 经停站列表
      show: false, // 编辑框卡关
      timetable: false, // 时刻表开关
      compliant: false, // 超标填表开关
      wrapper: false, // 遮罩层
      result: [], // 已选人员
      openSelectSeat: 0,
      tel: null, // 编辑框初始值
      explanation: REASONS[0], // 申请信息
      explanation1: "",
      explanationText: ""
    };
  },
  components: { LoadingAnimate, Head, BodyList, LoadingCircle },
  methods: {
    // 选超标理由
    onConfirm(value) {
      this.explanation = value;
      this.showPicker = false;
    },
    // 合规检查
    checkCanBuy(userRole, seatType = "二等座") {
      return checkSeat(userRole, seatType);
    },
    // 打开修改手机号
    openEdit(data) {
      // 保存手机号和下标，保存用
      this.tel = data.passengerPhone;
      this.show = true;
    },
    // 手机号校验
    validator(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    // 点击提交按钮，该方法仅做一些简单的验证，之后还需要调用合规检查，最后才可以提交占座
    onSubmit: async function () {
      let check = this.checkCanBuy(
        this.trainchange.peo,
        this.seatType[this.openSelectSeat].text
      );
      // 行程单余额控制
      // let checkPrice = await this.$store.dispatch("project/updateAppPrice", this.totalPrice);
      // if (this.travelInfo.isControl=="yes"&&!checkPrice) return this.$toast.fail("行程限额不足");
      // 如果大于0说明有超标
      if (!check) {
        this.explanationText =
          "合规座位:" + this.trainchange.peo.stand.trainSeatInfo_seatInfo_text || "无";
        this.compliant = true;
      } else {
        this.grabSeat();
      }
    },
    // 提交申请单，通过的话调用抢座
    subimtCompliance(action, done) {
      if (action === "confirm") {
        this.$refs.editCompliant
          .validate()
          .then(() => {
            this.grabSeat();
            done();
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    // 抢座、占座
    async grabSeat() {
      let seatInfo = this.seatType[this.openSelectSeat];
      this.wrapper = true;
      applyChangeOrder({
        trainInfo: this.ticket,
        ticketInfo: seatInfo,
        itemId: this.trainchange.peo.itemId,
        corp: this.trainchange.order.corp,
        orderNo: this.trainchange.order.orderNo
      })
        .then(async response => {
          let ticket1;
          while (1) {
            if (this.$route.name != "orderTrainChange") return;
            ticket1 = await refreshChangeTicket(response.data);
            if (ticket1) break;
          }
          this.$store.dispatch("history/setOrderItemChange", ticket1);
          this.$router.push({ path: "/plan" });
          // this.$router.push("/PaymentChange"); // 转到详情（已弃用，功能保留）
          this.wrapper = false;
        })
        .catch(err => {
          this.$toast.fail("占座失败请稍后再试");
          this.wrapper = false;
        });
    },
    // 修改手机号 确认
    subimtTel(action, done) {
      if (action === "confirm") {
        this.$refs.editTel
          .validate()
          .then(() => {
            // 把显示和结果集的手机号都修改一下
            this.trainchange.peo.passengerPhone = this.tel;
            done();
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    }
  }
};
</script>

<style lang="less" src="@/styles/orderCommon.less" scoped></style>
<style lang="less" src="@/styles/orderHeadCommon.less" scoped></style>
<style lang="less">
.explanCont textarea {
  height: 66px;
  max-height: 120px;
}
</style>
